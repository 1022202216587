import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './css/root.css';
import ErrorPage from "./error-page";
import Root from "./routes/root";
import Terms from "./routes/terms";
import Privacy from "./routes/privacy";
import Email, { loader as emailLoader } from "./routes/email";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/terms",
    element: <Terms />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy-policy",
    element: <Privacy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "e/:emailId",
    element: <Email />,
    loader: emailLoader,
    errorElement: <ErrorPage />,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);