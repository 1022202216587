import { useLoaderData } from "react-router-dom";
import { ref, get, child } from "firebase/database";
import db from "../lib/firebase";
import DOMPurify from "dompurify";
import '../css/app.css';

export async function loader({ params }) {
  const dbRef = ref(db);

  return get(child(dbRef, `emails/${params.emailId}`)).then((snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val();
      return data;

    } else {
      console.log("No data available");
      return null;
    }
  }).catch((error) => {
    console.error(error);
  })
}


export default function Email() {

  const email = useLoaderData();

  return (
    <>
      <div id="meta-container">
        <div id="meta-data" className="maxWidth">
          <p>from: <strong>{email.from.name}</strong> ({email.from.address})</p>
          <p>to: <strong>Lorenz Schimik</strong> (lorenz@mimo.org)</p>
          <p>Subject: <strong>{email.subject}</strong></p>
          <p>{formatDate(email.timestamp)}</p>
        </div>
      </div>
      <div
        id="email-content" className="maxWidth"
        dangerouslySetInnerHTML={createMarkup(email.content_html)}
      />
      <div id="banner">
        <p className="maxWidth">Shared via <a href="/">emailURL</a></p>
      </div>
    </>
  );

}

function createMarkup(emailContent) {
  let cleanHTML = DOMPurify.sanitize(emailContent);
  return {__html: cleanHTML};
}

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric", }
  const date = new Date(dateString).toLocaleDateString(undefined, options);
  const time = new Date(dateString).toLocaleTimeString('en-US');
  return date + " - " + time;
}