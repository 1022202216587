import LogoWhite from "../assets/emailURL-logo-white.svg";

export default function Footer() {
    return (
        <footer>
            <div id="footer-inner" className="content-wrapper">
                <a href="/"><img src={LogoWhite} id="footer-logo" alt="emailURL logo"/></a>
                <div className="footer-links">
                    <a href="/privacy-policy">Privacy</a>
                    <a href="/terms">Terms</a>
                </div>
            </div>
        </footer>
    );
  }