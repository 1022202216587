import '../css/landingpage.css';
import Lottie from "lottie-react";
import DemoAnimation from "../assets/demo.json";
import Logo from "../assets/emailURL-logo.svg";
import Footer from "../components/footer";

export default function Root() {
    return (
        <div id="root-container">
            <section id="landingpage">
                <div id="header-section" className="content-wrapper">
                    <a href="/" id="logo">
                        <img src={Logo} alt="emailURL logo"/>
                    </a>
                    <h1 className="header">The missing share button for Gmail</h1>
                    <a href="/">
                        <button className="primary-btn">Add to Chrome</button>
                    </a>
                    <Lottie className="animation" animationData={DemoAnimation} loop={true} />
                    <p className='body-text'>A simple Chrome Extension that let's you create a sharable URL for any email, directly from the Gmail interface. Find an example <a href="/">here</a>.</p>
                </div>
            </section>
            <Footer />
        </div>
    );
  }